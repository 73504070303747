import { STORYBLOK_IMAGE_BASE_URL } from "../constants";
import { getMimeTypeFromUrl } from "../getMimeTypeFromUrl";

/**
 * This helper does two things:
 * Convert asset urls to use the Akamai cache url.
 * Apply images transforms - the Storyblok image transformer is basic, so sometimes we skip the transforms.
 */

type StoryblokFocalPoint = `${number}x${number}:${number}x${number}`; // X1xY1:X2xY2

export interface ImageOptions {
  width?: number;
  height?: number;
  /**
   * https://www.storyblok.com/docs/image-service
   */
  smart?: boolean;
  /**
   * https://www.storyblok.com/docs/image-service#quality-optimization
   */
  quality?: number;
  /**
   * https://www.storyblok.com/docs/image-service#changing-the-format
   */
  format?: "webp" | "jpeg" | "png" | "avif";
  focal?: StoryblokFocalPoint;
}

export const getStoryblokImageUrl = (
  url: string,
  options: ImageOptions = {},
): string => {
  /**
   * Storyblok can't resize SVGs, so exclude them, and just return the Akamai URL.
   * TODO: we might need to add other rules here too.
   */
  if (getMimeTypeFromUrl(url) === "image/svg+xml") {
    return `${url.replace("//a.storyblok.com", STORYBLOK_IMAGE_BASE_URL)}`;
  }

  /**
   * If there are `options` append `/m/` to the image url. Must have that trailing slash.
   */
  const baseUrl = `${url}/m/`;

  /**
   * If there are no options, still run it through the transform with no options (append `/m/`). This still optimises the images.
   * This early return is technically redundant, but using it to enforce the point we should still run through the transform.
   */
  if (Object.keys(options).length === 0) {
    return `${baseUrl.replace("//a.storyblok.com", STORYBLOK_IMAGE_BASE_URL)}`;
  }

  // Collect the segments to append after `/m/`
  const segments: string[] = [];

  /**
   * Handle width and height:
   * Resized - Static = /500x500
   * Proportional to Width = /200x0
   * Proportional to Height = /0x200
   */
  const { width, height } = options;
  if (width || height) {
    segments.push(`${width || 0}x${height || 0}`);
  }

  // Handle smart crop
  if (options.smart) {
    segments.push("smart");
  }

  // Handle filters
  const filters: string[] = [];
  if (options.quality !== undefined) {
    filters.push(`quality(${options.quality})`);
  }
  if (options.format) {
    filters.push(`format(${options.format})`);
  }
  if (options.focal) {
    filters.push(`focal(${options.focal})`);
  }
  if (filters.length > 0) {
    segments.push(`filters:${filters.join(":")}`);
  }

  /**
   * Join all the segments with `/` and append to the base URL, example:
   * https://a.storyblok.com/f/39898/3310x2192/e4ec08624e/demo-image.jpeg/m/{widthxheight}/{smart}/filters:format(png):quality(80)
   *
   * Serve assets via the cheaper Akamai cahce, and bypass geo-blocking.
   */
  return `${baseUrl.replace("//a.storyblok.com", STORYBLOK_IMAGE_BASE_URL)}${segments.join("/")}`;
};
