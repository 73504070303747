/**
 * Storyblok does not return the Mime type for assets.
 * We need to check this sometimes for layouts involving images or videos.
 * This is simple helper to "assume" the mime type based on file extension.
 */

export const getMimeTypeFromUrl = (url: string): string => {
  // Strip out query strings and fragments, we only care about the actual file path
  const cleanUrl = url.split(/[?#]/)[0]; // Remove any query string (after '?') or fragment (after '#')

  // Extract the file name (part after the last '/')
  const fileName = cleanUrl.split("/").pop();

  // Extract the file extension, or undefined if not present
  const extension = fileName?.split(".").pop()?.toLowerCase();

  /**
   * TODO: This error probably never gets triggered.
   * as any string will get returned
   */
  if (!extension) {
    throw new Error("Invalid asset URL: Unable to extract file extension");
  }

  switch (extension) {
    case "avif":
      return "image/avif";
    case "jpg":
    case "jpeg":
      return "image/jpeg";
    case "png":
      return "image/png";
    case "gif":
      return "image/gif";
    case "webp":
      return "image/webp";
    case "svg":
      return "image/svg+xml";
    case "pdf":
      return "application/pdf";
    case "mp4":
      return "video/mp4";
    case "mp3":
      return "audio/mpeg";
    default:
      return "application/octet-stream"; // Default to binary type
  }
};
